import http from '@/api/request'

// 教师信息分页
export function teacherPage (data = {}) {
  return http().post('/base/api/teacher/page', data)
}

// 教师信息查看
export function teacherView (id) {
  return http().get(`/base/api/teacher/view?id=${id}`)
}

// 教师课程查询
export function teacherCoursePage (data = {}) {
  return http().post('/resource/api/course/page', data)
}

// 教师等级
export function teacherRankList (data = {}) {
  return http().post('/base/api/teacher/rank/list', data)
}

// 教师信息搜索
export function teacherSearch (data = {}) {
  return http().post('/base/api/teacher/search', data)
}

// 教师入驻申请
export function teacherApply (data = {}) {
  return http().post('/base/auth/teacher/apply', data)
}

// 教师入驻申请详情
export function teacherApplyView (id) {
  return http().get(`/base/auth/teacher/apply/view?id=${id}`)
}

// 教师入驻重新申请
export function teacherApplyAfresh (data = {}) {
  return http().put('/base/auth/teacher/apply/afresh', data)
}

// 教师入驻申请撤销
export function teacherApplyRevoke (data = {}) {
  return http().put(`/base/auth/teacher/apply/revoke?id=${data.id}`)
}

// 教师入驻申请-记录分页
export function teacherApplyRecordPage (data = {}) {
  return http().post('/base/auth/teacher/apply/record/page', data)
}
